import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>West 117 Solutions</h1>
    <h3>Full stack software engineering</h3>
    <p>Site isn't quite finished yet...</p>
  </Layout>
)

export default IndexPage
